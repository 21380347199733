import React, { PureComponent } from 'react';
import Box from '@mui/material/Box';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { PieChart } from 'react-minimal-pie-chart';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import styles from './Overview.module.scss';
import DatesSelector from '../../containers/DatesSelector/DatesSelector';
import AdSelector from '../../components/AdSelector/AdSelector';
import ActiveDomain from '../../redux/actions/ActiveDomain';
import Data from '../../api/Data';
import Utils from '../../utils/Utils';

// Images

import CheckIcon from '../../assets/box-check.svg';
import MinusIcon from '../../assets/_minus.svg';
import MobileIcon from '../../assets/mobileIcon.svg';
import DeskIcon from '../../assets/deskIcon.svg';
import NotInstalled from '../../assets/fraud-small.svg';
import NotConnect from '../../assets/nt-connect.svg';
import NoDataGraph from '../../assets/nodata.png';
import NoteIcon from '../../assets/note-icon.png';
import MetaAdsIcon from '../../assets/meta-ads-blue.png';
import GoogleAdsIcon from '../../assets/google-ads-blue.png';

const SucessLinearProgress = styled(LinearProgress)(() => ({
  height: 15,
  borderRadius: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F2F4F6'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    backgroundColor: '#10CD24'
  }
}));

const WarningLinearProgress = styled(LinearProgress)(() => ({
  height: 15,
  borderRadius: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F2F4F6'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    backgroundColor: '#F99400CC'
  }
}));

const DangerLinearProgress = styled(LinearProgress)(() => ({
  height: 15,
  borderRadius: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F2F4F6'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    backgroundColor: '#FC584ECC'
  }
}));

const fraudTypes = [
  { title: 'Excessive Clicks', key: 'excessiveClickerClicks', color: '#FF8585' },
  { title: 'VPN / DataCenter', key: 'botDatacenterClicks', color: '#CC0025' },
  { title: 'Blacklist / Abuse', key: 'abuseHighRiskClicks', color: '#FF8685' },
  { title: 'Risky Geo', key: 'riskyGeoClicks', color: '#F25B52' },
  { title: 'Accidental Clicks', key: 'accidentalClickerClicks', color: '#95031D' },
  { title: 'Risky Device', key: 'riskyDeviceClicks', color: '#FFBCBC' }
];

class Overview extends PureComponent {
  state = {
    fetchingData: true,
    fetchingStats: true,
    adType: 'gclid',
    startDate: null,
    endDate: null,
    errors: {},
    statsError: {},
    stats: {},
    domainsSummary: [],
    isAnyDomainConnected: false
  };

  componentDidMount = async () => {
    try {
      const { accounts } = this.props;
      await this.fetchData(
        {
          startDate:
            localStorage.getItem('start_date') ||
            moment()
              .subtract(3, 'days')
              .format('YYYYMMDD'),
          endDate: localStorage.getItem('end_date') || moment().format('YYYYMMDD')
        },
        null
      );
      const isAnyDomainConnected = accounts.data.domains.some(
        domain =>
          domain.google_ads_token || domain.data_sync_success || domain.pixel_install_success
      );
      this.setState({ fetchingData: false, isAnyDomainConnected });
    } catch (error) {
      this.setState({ fetchingData: false });
    }
  };

  setupCurrentDomain = sid => {
    const { accounts, setDomain, history } = this.props;
    if (accounts && accounts.data && accounts.data.domains) {
      setDomain(accounts.data.domains.find(domain => domain.id === sid));
      history.push('/integrations/google-ads-setup');
    }
  };

  fraudNotInstalled = sid => (
    <span key={null} className={`${styles.baseMess}`} onClick={() => this.setupCurrentDomain(sid)}>
      <span>
        <img src={NotInstalled} />
      </span>
      Not Installed
    </span>
  );

  fraudNotConnect = (sid, hideIcon = false) => (
    <span
      key={null}
      className={`${styles.baseMess} ${hideIcon ? styles.noIcon : ''}`}
      onClick={() => this.setupCurrentDomain(sid)}
    >
      {!hideIcon && (
        <span>
          <img src={NotConnect} />
        </span>
      )}
      Not Connected
    </span>
  );

  /**
   * Fetch Data for Result Table
   * @param {Object} body {
   * startDate: 'YYYYMMDD',
   * endDate: 'YYYYMMDD'
   * }
   */
  fetchData = async body => {
    try {
      const { accounts, auth } = this.props;
      if (!accounts.data || !accounts.data.domains || !accounts.data.domains.length) {
        return;
      }
      const sids = accounts.data.domains
        .filter(item => item.is_deleted === false)
        .map(domain => domain.id);
      if (!sids.length) {
        return;
      }
      const { timezone } = auth.user;
      this.setState({
        startDate: body.startDate || this.state.startDate,
        endDate: body.endDate || this.state.endDate,
        adType: body.adType || this.state.adType
      });
      const query = {
        startDate: moment(body.startDate || this.state.startDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        endDate: moment(body.endDate || this.state.endDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        sid: sids,
        isAggressive: false,
        timezone: Utils.sanitizeTimezoneString(timezone || '(GMT-07:00) America/Los_Angeles'),
        adType:
          (body.adType || this.state.adType) === 'all'
            ? undefined
            : body.adType || this.state.adType
      };
      this.fetchStats(query);
      this.setState({ fetchingData: true });
      const result = await Data.getDashboardSummary(query);
      if (result && !result.errno && result.length) {
        const filteredResults = accounts.data.domains
          .filter(item => item.is_deleted === false)
          .map(item => {
            return result.find(domain => domain.sid === item.id);
          })
          .filter(Boolean);

        const totalSavings = filteredResults.reduce((acc, item) => {
          return (
            acc +
            (item.blockedClicks || 0) *
            (accounts.data.domains.find(domain => domain.id === item.sid).cpc || 2)
          );
        }, 0);
        const totalViews = filteredResults.reduce((acc, item) => {
          return acc + (item.visitors || 0);
        }, 0);
        const totalClicks = filteredResults.reduce((acc, item) => {
          return acc + (item.clicks || 0);
        }, 0);
        const totalBlockedClicks = filteredResults.reduce((acc, item) => {
          return acc + (item.blockedClicks || 0);
        }, 0);
        const totalFraudScore =
          filteredResults.reduce((acc, item) => {
            return (
              acc +
              Number(Utils.calcFraudScore(item.percentBlocked, 1) || '0') *
              (item.blockedClicks || 0)
            );
          }, 0) / totalBlockedClicks;
        const totalPerBlocked = (totalBlockedClicks * 100) / totalClicks;

        const summary = accounts.data.domains
          .filter(item => item.is_deleted === false)
          .map(item => {
            const relatedResult = result.find(domain => domain.sid === item.id);
            return {
              sid: item.id,
              savings: relatedResult ? (relatedResult.blockedClicks || 0) * (item.cpc || 2) : 0,
              domain: item.domain_name,
              connected: item.data_sync_success || item.google_ads_token,
              installed: item.pixel_install_success,
              metaToken: item.meta_ads_token,
              ...(relatedResult || {})
            };
          });
        this.setState({
          domainsSummary: summary,
          totalSavings,
          totalViews: totalViews.toLocaleString('en-US', { maximumFractionDigits: 1 }),
          totalClicks: totalClicks.toLocaleString('en-US', { maximumFractionDigits: 1 }),
          totalFraudScore: totalFraudScore.toLocaleString('en-US', { maximumFractionDigits: 1 }),
          totalBlockedClicks: totalBlockedClicks.toLocaleString('en-US', {
            maximumFractionDigits: 1
          }),
          totalPerBlocked: Number(totalPerBlocked.toFixed(1)).toLocaleString('en-US', {
            maximumFractionDigits: 1
          }),
          errors: {}
        });
      } else {
        const summary = accounts.data.domains
          .filter(item => item.is_deleted === false)
          .map(item => {
            return {
              sid: item.id,
              savings: 0,
              domain: item.domain_name,
              connected: item.data_sync_success || item.google_ads_token,
              installed: item.pixel_install_success,
              metaToken: item.meta_ads_token
            };
          });
        this.setState({
          domainsSummary: summary,
          totalSavings: 0.0,
          totalViews: 0,
          totalClicks: 0,
          totalBlockedClicks: 0,
          totalPerBlocked: 0,
          totalFraudScore: 0
        });
      }
      this.setState({ fetchingData: false });
    } catch (error) {
      console.log(error);
      this.setState({ errors: error });
      this.setState({ fetchingData: false });
    }
  };

  fetchStats = async query => {
    try {
      this.setState({ fetchingStats: true, statsError: {} });
      const result = await Data.getStats(query);
      if (result && !result.errno && result.length) {
        this.setState({ stats: result[0] });
      }

      this.setState({ fetchingStats: false });
    } catch (error) {
      console.log(error);
      this.setState({ statsError: error, fetchingStats: false });
    }
  };

  gotoDomainDashboard = sid => {
    const { accounts, setDomain, history } = this.props;
    if (accounts && accounts.data && accounts.data.domains) {
      setDomain(accounts.data.domains.find(domain => domain.id === sid));
      history.push('/dashboard');
    }
  };

  render() {
    const {
      stats,
      totalSavings,
      domainsSummary,
      totalViews,
      totalFraudScore,
      totalClicks,
      totalBlockedClicks,
      totalPerBlocked,
      isAnyDomainConnected
    } = this.state;
    const { accounts, auth } = this.props;
    const conversionRates = accounts ? accounts.conversionRates : [];
    const currency = auth.user && auth.user.currency ? auth.user.currency : 'USD';
    const subscription =
      accounts && accounts.data ? Utils.getSingleSubscription(accounts, accounts.data.id) : null;
    return (
      <div className={`${styles.content} ${styles.overviewContent}`}>
        <div className={styles.header}>
          <h1 className={styles.title}>Overview</h1>
        </div>
        <div className={styles.topFiltersWrap}>
          <AdSelector showAll={false} handleAdChange={this.fetchData} />
          <DatesSelector handleDateChange={this.fetchData} />
        </div>
        {this.state.adType === 'msclkid' && (
          <div className={styles.info}>
            <div>
              <img src={NoteIcon} alt="alert" />
              IPs cannot be blocked automatically for Microsoft Ads.{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://help.fraudblocker.com/en/articles/8224653-can-i-protect-my-microsoft-ads-campaigns"
              >
                Learn More
              </a>
              .
            </div>
          </div>
        )}
        <div className={styles.overviewStatus}>
          <div className={`${styles.totalSavings} ${styles.statusBox}`}>
            <div className={styles.statusHeading}>Total Savings</div>
            <div className={styles.statusValue}>
              {isAnyDomainConnected
                ? totalSavings &&
                Utils.convertToCurrency(
                  conversionRates,
                  Number(totalSavings.toFixed(2)),
                  currency
                )
                : Utils.convertToCurrency(
                  conversionRates,
                  0,
                  currency
                )}
            </div>
          </div>
          <div className={`${styles.statusGrid} ${styles.statusBox}`}>
            <div className={`${styles.statusBoxInner} ${styles.successBox}`}>
              <div className={styles.iconBox}>
                <img src={CheckIcon} />
              </div>
              <div className={styles.boxDetais}>
                <div className={styles.statusHeading}>Clean</div>
                {isAnyDomainConnected ? (
                  <div className={styles.statusValue}>
                    {(stats.cleanClicks || 0).toLocaleString('en-US', { maximumFractionDigits: 1 })}{' '}
                    (
                    {stats.cleanClicks
                      ? ((stats.cleanClicks * 100) / stats.clicks).toLocaleString('en-US', {
                        maximumFractionDigits: 1
                      })
                      : 0}
                    %)
                  </div>
                ) : (
                  <div className={styles.statusValueDisabled}>Pending</div>
                )}
              </div>
            </div>
          </div>
          <div className={`${styles.statusGrid} ${styles.statusBox}`}>
            <div className={`${styles.statusBoxInner} ${styles.warningBox}`}>
              <div className={styles.iconBox}>
                <img src={MinusIcon} />
              </div>
              <div className={styles.boxDetais}>
                <div className={styles.statusHeading}>Suspected</div>
                {isAnyDomainConnected ? (
                  <div className={styles.statusValue}>
                    {(stats.suspectedClicks || 0).toLocaleString('en-US', {
                      maximumFractionDigits: 1
                    })}{' '}
                    (
                    {stats.suspectedClicks
                      ? ((stats.suspectedClicks * 100) / stats.clicks).toLocaleString('en-US', {
                        maximumFractionDigits: 1
                      })
                      : 0}
                    %)
                  </div>
                ) : (
                  <div className={styles.statusValueDisabled}>Pending</div>
                )}
              </div>
            </div>
          </div>
          <div className={`${styles.statusGrid} ${styles.statusBox}`}>
            <div className={`${styles.statusBoxInner} ${styles.dangerBox}`}>
              <div className={styles.iconBox}>
                <strong style={{ marginTop: '-2px' }}>!</strong>
              </div>
              <div className={styles.boxDetais}>
                <div className={styles.statusHeading}>Invalid</div>
                {isAnyDomainConnected ? (
                  <div className={styles.statusValue}>
                    {(stats.fraudClicks || 0).toLocaleString('en-US', { maximumFractionDigits: 1 })}{' '}
                    (
                    {stats.fraudClicks
                      ? ((stats.fraudClicks * 100) / stats.clicks).toLocaleString('en-US', {
                        maximumFractionDigits: 1
                      })
                      : 0}
                    %)
                  </div>
                ) : (
                  <div className={styles.statusValueDisabled}>Pending</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.overviewDetails}>
          <div className={styles.overviewNetwork}>
            <div className={styles.gridHeading}>Ad Network</div>
            <div className={styles.detailBox}>
              <div className={styles.progressStats}>
                {isAnyDomainConnected ? (
                  <>
                    <div className={styles.progressRow}>
                      <div className={styles.progressWrapper}>
                        <Box sx={{ width: '100%' }}>
                          <SucessLinearProgress
                            variant="determinate"
                            value={stats.cleanClicks ? (stats.cleanClicks * 100) / stats.clicks : 0}
                          />
                        </Box>
                      </div>
                      <div className={styles.boxDetais}>
                        <div className={styles.statusHeading}>Clean</div>
                        <div className={`${styles.statusValue} ${styles.successColor}`}>
                          {stats.cleanClicks
                            ? ((stats.cleanClicks * 100) / stats.clicks).toLocaleString('en-US', {
                              maximumFractionDigits: 1
                            })
                            : 0}
                          %
                        </div>
                      </div>
                    </div>
                    <div className={styles.progressRow}>
                      <div className={styles.progressWrapper}>
                        <Box sx={{ width: '100%' }}>
                          <WarningLinearProgress
                            variant="determinate"
                            value={
                              stats.suspectedClicks
                                ? (stats.suspectedClicks * 100) / stats.clicks
                                : 0
                            }
                          />
                        </Box>
                      </div>
                      <div className={styles.boxDetais}>
                        <div className={styles.statusHeading}>Suspected</div>
                        <div className={`${styles.statusValue} ${styles.warningColor}`}>
                          {stats.suspectedClicks
                            ? ((stats.suspectedClicks * 100) / stats.clicks).toLocaleString(
                              'en-US',
                              { maximumFractionDigits: 1 }
                            )
                            : 0}
                          %
                        </div>
                      </div>
                    </div>
                    <div className={styles.progressRow}>
                      <div className={styles.progressWrapper}>
                        <Box sx={{ width: '100%' }}>
                          <DangerLinearProgress
                            variant="determinate"
                            value={stats.fraudClicks ? (stats.fraudClicks * 100) / stats.clicks : 0}
                          />
                        </Box>
                      </div>
                      <div className={styles.boxDetais}>
                        <div className={styles.statusHeading}>Invalid</div>
                        <div className={`${styles.statusValue} ${styles.dangerColor}`}>
                          {stats.fraudClicks
                            ? ((stats.fraudClicks * 100) / stats.clicks).toLocaleString('en-US', {
                              maximumFractionDigits: 1
                            })
                            : 0}
                          %
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.noAdMess}>
                    You have no data yet. Please connect your Google Ads account(s).
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.overviewType}>
            <div className={styles.gridHeading}>Fraud Type</div>
            <div className={styles.detailBox}>
              <div className={styles.fraudList}>
                <ul>
                  {fraudTypes.map((type, index) => (
                    <li key={index}>
                      <span className={styles.listLabel}>{type.title}</span>
                      <span
                        className={styles.listValue}
                        style={{ color: isAnyDomainConnected ? type.color : '#CBCBCB' }}
                      >
                        {stats[type.key]
                          ? ((stats[type.key] * 100) / stats.fraudClicks).toFixed(1)
                          : 0}
                        %
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.fraudGraph}>
                <div className={styles.nodataGraph}>
                  {isAnyDomainConnected ? (
                    <PieChart
                      lineWidth={15}
                      paddingAngle={5}
                      animate
                      data={fraudTypes.map(type => ({
                        title: type.title,
                        value: stats[type.key] ? (stats[type.key] * 100) / stats.fraudClicks : 0,
                        color: type.color
                      }))}
                    />
                  ) : (
                    <>
                      <img src={NoDataGraph} />
                      <span>No data yet</span>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.fraudDevice}>
                <div className={styles.fraudDeviceType}>
                  <div className={styles.fraudDeviceIcon}>
                    <img src={MobileIcon} />
                  </div>
                  <div className={styles.fraudDeviceDetails}>
                    <div className={styles.deviceName}>Mobile</div>
                    <div className={styles.deviceValue}>
                      {stats.fraudClicksMobile &&
                        stats.fraudClicksMobile.toLocaleString('en-US', {
                          maximumFractionDigits: 1
                        })}
                    </div>
                  </div>
                </div>
                <div className={styles.fraudDeviceType}>
                  <div className={styles.fraudDeviceIcon}>
                    <img src={DeskIcon} />
                  </div>
                  <div className={styles.fraudDeviceDetails}>
                    <div className={styles.deviceName}>Desktop</div>
                    <div className={styles.deviceValue}>
                      {stats.fraudClicksDesktop &&
                        stats.fraudClicksDesktop.toLocaleString('en-US', {
                          maximumFractionDigits: 1
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.overviewTable}>
          <div className={styles.gridHeading}>Website Summary</div>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Website Name</TableCell>
                  <TableCell align="center">Connection</TableCell>
                  <TableCell align="center">Total Visits</TableCell>
                  <TableCell align="center">Ad Visits</TableCell>
                  <TableCell align="center">Blocked Clicks</TableCell>
                  <TableCell align="center">%Blocked</TableCell>
                  <TableCell align="center">Fraud Score</TableCell>
                  <TableCell>Savings</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {domainsSummary.map(row => (
                  <TableRow
                    key={row.sid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <a href={null} onClick={() => this.gotoDomainDashboard(row.sid)}>
                        {row.domain}
                      </a>
                    </TableCell>
                    <TableCell align="center" className={styles.secondTd}>
                      {row.installed || row.metaToken ? (
                        <span
                          className={`${styles.adType} ${!(row.installed && row.metaToken) ? styles.singleAd : ''
                            }`}
                        >
                          {row.installed && <img src={GoogleAdsIcon} alt="google" />}
                          {row.metaToken && <img src={MetaAdsIcon} alt="meta" />}
                        </span>
                      ) : (
                        this.fraudNotConnect(row.sid, true)
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.installed
                        ? (row.visitors || 0).toLocaleString('en-US', { maximumFractionDigits: 1 })
                        : this.fraudNotInstalled(row.sid)}
                    </TableCell>
                    <TableCell align="center" className={styles.thirdTd}>
                      {(row.clicks || 0).toLocaleString('en-US', { maximumFractionDigits: 1 }) ||
                        ''}
                    </TableCell>
                    <TableCell align="center">
                      {(row.blockedClicks || 0).toLocaleString('en-US', {
                        maximumFractionDigits: 1
                      }) || ''}
                    </TableCell>
                    <TableCell align="center">
                      {`${(row.percentBlocked || 0).toFixed(1)}%` || ''}
                    </TableCell>
                    <TableCell align="center">
                      {Utils.calcFraudScore(row.percentBlocked, 1) || ''}
                    </TableCell>
                    <TableCell align="left" className={styles.priceBold}>
                      {row.savings
                        ? row.savings &&
                        Utils.convertToCurrency(
                          conversionRates,
                          Number(row.savings.toFixed(2)),
                          currency
                        )
                        : Utils.convertToCurrency(
                          conversionRates,
                          0,
                          currency
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{totalViews}</TableCell>
                  <TableCell align="center">{totalClicks}</TableCell>
                  <TableCell align="center">{totalBlockedClicks}</TableCell>
                  <TableCell align="center">{totalPerBlocked}%</TableCell>
                  <TableCell align="center">{totalFraudScore}</TableCell>
                  <TableCell className={styles.priceBold}>
                    {isAnyDomainConnected
                      ? totalSavings &&
                      Utils.convertToCurrency(
                        conversionRates,
                        Number(totalSavings.toFixed(2)),
                        currency
                      )
                      : Utils.convertToCurrency(
                        conversionRates,
                        0,
                        currency
                      )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        {subscription &&
          subscription.plan &&
          subscription.plan.metadata.domains !== 'unlimited' &&
          accounts.data.domains.filter(item => item.is_deleted === false).length >=
          subscription.plan.metadata.domains &&
          (!subscription.metadata.domain ||
            accounts.data.domains.filter(item => item.is_deleted === false).length >=
            parseInt(subscription.metadata.domain, 10)) && (
            <div className={styles.needMore}>
              <Link to="/account/billing/subscription" state={{ showPlansPopup: true }}>
                Need more websites? Upgrade
              </Link>
            </div>
          )}
        {accounts.data &&
          accounts.data.domains &&
          accounts.data.domains.filter(item => item.is_deleted === false).length > 0 && (
            <div className={styles.deleteWebsite}>
              <Link to="/account/billing/subscription">Delete a website</Link>
            </div>
          )}
      </div>
    );
  }
}

Overview.propTypes = {
  accounts: PropTypes.object,
  history: PropTypes.any,
  setDomain: PropTypes.func,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  accounts: state.accounts,
  auth: state.auth
});

const mapDispatchToProps = dispatch => {
  return {
    setDomain: domain => dispatch(ActiveDomain.setDomainActive(domain))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
