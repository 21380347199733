import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ActiveDomain from '../../redux/actions/ActiveDomain';
import AdSelector from '../../components/AdSelector/AdSelector';
import styles from './Dashboard.module.scss';
import BlockedIPs from '../../assets/blocked-i-ps.svg';
import TotalSaved from '../../assets/total-saved.svg';
import FraudScore from '../../assets/fraud-score.svg';
import Data from '../../api/Data';
import Constants from '../../utils/Constants';
import Utils from '../../utils/Utils';
import OrganicVisitors from '../../assets/eye-view.svg';
import VisitorsAdvertising from '../../assets/visitors-ads.svg';
import FraudDetectionRate from '../../assets/fraud-detection.svg';
import AdsClickRate from '../../assets/ads-click.svg';
import Chart from '../../components/Chart/Chart';
import Input from '../../components/Input/Input';
import EMPTY_REPORT from '../../assets/empty2.svg';
import TOOLTIP from '../../assets/tooltip.svg';
import ANALYZING from '../../assets/analyze.svg';
import DatesSelector from '../DatesSelector/DatesSelector';
import NoteIcon from '../../assets/note-icon.png';

const { roundAmount } = Constants;

const customStyles = {
  totalSavedFeatured: {
    marginRight: 30,
    marginLeft: 30
  },
  frontSubFeatured: {
    marginRight: 15
  },
  middleSubFeatured: {
    marginRight: 15,
    marginLeft: 15
  },
  endSubFeatured: {
    marginLeft: 15
  },
  cpcContainer: {
    display: 'inline-block',
    width: '85px',
    padding: '12px 0 20px',
    position: 'static'
  },
  cpcInput: {
    width: '50px',
    borderRadius: '0px',
    padding: '2px 6px'
  },
  cpcLabel: {
    fontSize: '14px',
    color: '#4a4a4a'
  },
  cpcError: {
    position: 'absolute',
    bottom: '28px'
  }
};

const DEFAULT_SUMMARY = {
  clicks: 0,
  avgRiskFromAds: 0,
  avgRiskOrganic: 0,
  avgRiskTotal: 0,
  visitorsFromAds: 0,
  organicVisitors: 0,
  blockedClicks: 0,
  percentBlocked: 0,
  visitors: 0,
  blockedIPsFromAds: 0,
  blockedIPsOrganic: 0,
  blockedIPs: 0,
  invalidTrafficRate: 0
};

class Dashboard extends PureComponent {
  state = {
    blockedIPs: {
      total: 1253,
      image: BlockedIPs
    },
    totalSaved: {
      total: 950,
      image: TotalSaved
    },
    fraudScore: {
      total: 4.6,
      image: FraudScore
    },
    fetchingOrganic: true,
    fetchingSummary: true,
    fetchingChart: true,
    adType: 'gclid',
    startDate: null,
    endDate: null,
    errors: {},
    chartErrors: {},
    organicStats: {},
    summary: DEFAULT_SUMMARY,
    chart: [],
    cpc: 2,
    editing: false
  };

  componentDidMount = () => {
    this.fetchData({
      startDate:
        localStorage.getItem('start_date') ||
        moment()
          .subtract(3, 'days')
          .format('YYYYMMDD'),
      endDate: localStorage.getItem('end_date') || moment().format('YYYYMMDD')
    });
    const { accounts, auth } = this.props;
    const conversionRates = accounts ? accounts.conversionRates : [];
    this.setState({
      cpc: roundAmount(
        Utils.convertToCurrencyNumeric(
          conversionRates,
          this.props.activeDomain.data.cpc || 2,
          auth.user.currency,
          true
        )
      )
    });
  };

  componentDidUpdate = preProps => {
    if (
      this.props.activeDomain &&
      this.props.activeDomain.data &&
      preProps.activeDomain &&
      preProps.activeDomain.data &&
      preProps.activeDomain.data.id !== this.props.activeDomain.data.id
    ) {
      this.fetchData({
        startDate:
          localStorage.getItem('start_date') ||
          moment()
            .subtract(3, 'days')
            .format('YYYYMMDD'),
        endDate: localStorage.getItem('end_date') || moment().format('YYYYMMDD')
      });
      const { accounts, auth } = this.props;
      const conversionRates = accounts ? accounts.conversionRates : [];
      this.setState({
        cpc: roundAmount(
          Utils.convertToCurrencyNumeric(
            conversionRates,
            this.props.activeDomain.data.cpc || 2,
            auth.user.currency,
            true
          )
        )
      });
    }
    ReactTooltip.rebuild();
  };

  prepareChartData = data => {
    const chartData = data.map(item => ({
      name: moment(item.date.value, 'YYYY-MM-DD').format('MMM DD'),
      uv: item.visitorsFromAds ? item.visitorsFromAds : 0,
      pv: item.blockedIPsFromAds ? item.blockedIPsFromAds : 0
    }));
    this.setState({ chart: chartData, chartErrors: {} });
  };

  /**
   * Fetch Data for Chart
   * @param {Object} body {
   * startDate: 'YYYYMMDD',
   * endDate: 'YYYYMMDD'
   * }
   */
  // Main function that handles the overall data fetching process
  fetchData = async (body) => {
    try {
      // Early return if activeDomain or required properties are not available
      if (!this.props.activeDomain || !this.props.activeDomain.data || !this.props.activeDomain.data.id) {
        return;
      }

      // Extract timezone and update component state with body parameters
      const { timezone } = this.props.auth.user;
      this.setState({
        startDate: body.startDate || this.state.startDate,
        endDate: body.endDate || this.state.endDate,
        adType: body.adType || this.state.adType,
      });

      // Construct the query object for API calls
      const query = {
        startDate: moment(body.startDate || this.state.startDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        endDate: moment(body.endDate || this.state.endDate, 'YYYYMMDD').format('YYYY-MM-DD'),
        sid: this.props.activeDomain.data.id,
        isAggressive: this.props.activeDomain.data.aggressive_blocking,
        timezone: Utils.sanitizeTimezoneString(timezone || '(GMT-07:00) America/Los_Angeles'),
        adType: (body.adType || this.state.adType) === 'all' ? undefined : body.adType || this.state.adType,
      };

      // Set fetchingData to true before starting any data fetching
      this.setState({
        fetchingOrganic: true,
        fetchingSummary: true,
        fetchingChart: true,
      });

      // Fire off each function independently without awaiting here
      this.fetchOrganicStats(query);
      this.fetchDashboardSummary(query);
      this.fetchChartData(query);
    } catch (error) {
      // Catch errors in the main fetchData function scope
      console.error(error);
      this.setState({
        errors: error
      });
    }
  };

  // Fetch organic stats and update state as soon as it resolves
  fetchOrganicStats = async (query) => {
    try {
      const organicStatsResult = await Data.getOrganicStats(query);
      if (organicStatsResult && !organicStatsResult.errno && organicStatsResult.length) {
        this.setState({
          organicStats: organicStatsResult[0]
        });
      } else {
        this.setState({ organicStats: {} });
      }
    } catch (error) {
      console.error("Error fetching organic stats:", error);
      this.setState({ errors: error });
    } finally {
      this.setState({ fetchingOrganic: false });
    }
  };

  fetchDashboardSummary = async (query) => {
    try {
      const result = await Data.getDashboardSummary(query);
      if (result && !result.errno && result.length) {
        this.setState({ summary: result[0], errors: {} });
      } else {
        this.setState({ summary: DEFAULT_SUMMARY, errors: {} });
      }
    } catch (error) {
      console.error("Error fetching dashboard summary:", error);
      this.setState({ errors: error });
    } finally {
      this.setState({ fetchingSummary: false });
    }
  };

  fetchChartData = async (query) => {
    try {
      const result = await Data.getDashboardChart(query);
      console.log('CHART', result);
      if (result && !result.errno && result.length) {
        this.prepareChartData(result);
      } else {
        this.setState({ chart: [], chartErrors: {} });
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
      this.setState({ chartErrors: error });
    } finally {
      this.setState({ fetchingChart: false });
    }
  };


  isValidCPC = cpc => {
    return (cpc || cpc === 0 || cpc === '0') && !Number.isNaN(cpc);
  };

  enableEdit = async () => {
    if (this.state.editing) {
      if (this.isValidCPC(this.state.cpc)) {
        const { accounts, auth } = this.props;
        const conversionRates = accounts ? accounts.conversionRates : [];
        await this.props.updateDomain(this.props.activeDomain.data.id, {
          id: this.props.activeDomain.data.id,
          cpc: parseFloat(
            Utils.convertToUSD(conversionRates, this.state.cpc, auth.user.currency, true)
          )
        });
        this.setState({ editing: false });
      }
    } else {
      this.setState({ editing: true });
    }
  };

  handleCPC = evt => {
    this.setState({ cpc: evt.target.value });
  };

  roundAndFormat = amount => {
    if (!amount) {
      return amount;
    }
    const rounded = roundAmount(amount);
    return `${Number(rounded.toString().split('.')[0]).toLocaleString('en-US')}.${rounded.toString().split('.')[1]
      }`;
  };

  render() {
    const {
      blockedIPs,
      totalSaved,
      fraudScore,
      summary,
      chart,
      editing,
      cpc,
      fetchingChart,
      fetchingOrganic,
      fetchingSummary,
      organicStats
    } = this.state;
    const { activeDomain, accounts, auth } = this.props;

    const connected = activeDomain && activeDomain.data && !!activeDomain.data.google_ads_token;
    const currency = auth.user && auth.user.currency ? auth.user.currency : 'USD';
    const conversionRates = accounts ? accounts.conversionRates : [];

    return (
      <div className={styles.wrapper}>
        <ReactTooltip id="blockedIps" className={styles.tooltipContent}>
          <div>
            This represents the clicks from your advertising campaigns that were determined to be
            invalid, or fraudulent, based on our scoring criteria.
          </div>
        </ReactTooltip>
        <ReactTooltip
          effect="solid"
          delayHide={500}
          delayUpdate={500}
          id="totalSaved"
          multiline={true}
          className={styles.tooltipContent}
        >
          <div>
            This is your estimated savings during the period you selected based on the percent of
            invalid, or fraudulent, traffic we detected. <a href="https://help.fraudblocker.com/en/articles/10055556-how-is-savings-calculated" rel="noopener noreferrer" target="_blank">Learn more.</a>
          </div>
        </ReactTooltip>
        {/* {!accounts.subscriptionValid && <div className={styles.overlay} />} */}
        <div className={styles.content}>
          <div className={styles.header}>
            <h1 className={styles.title}>Dashboard</h1>
          </div>
          <div className={styles.topFiltersWrap}>
            <AdSelector showAll={false} handleAdChange={this.fetchData} />
            <DatesSelector handleDateChange={this.fetchData} />
          </div>
          {this.state.adType === 'msclkid' && (
            <div className={styles.info}>
              <div>
                <img src={NoteIcon} alt="alert" />
                IPs cannot be blocked automatically for Microsoft Ads.{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://help.fraudblocker.com/en/articles/8224653-can-i-protect-my-microsoft-ads-campaigns"
                >
                  Learn More
                </a>
                .
              </div>
            </div>
          )}
          {!fetchingSummary ? (
            <div className={styles.featuredContainer}>
              <div className={styles.featured}>
                <div className={styles.featuredInner}>
                  <div className={styles.featureIcon}>
                    <img className={styles.smallFeatureIcon} src={blockedIPs.image} />
                  </div>
                  <div className={styles.featuredDescriptions}>
                    <p className={styles.featuredTitle}>
                      Invalid Clicks{' '}
                      <a data-tip data-for="blockedIps">
                        <img className={styles.tooltip} src={TOOLTIP} />
                      </a>
                    </p>
                    <p className={styles.featuredTotal}>
                      {(summary.blockedClicks || 0).toLocaleString('en-US', {
                        maximumFractionDigits: 1
                      })}
                    </p>
                    <p>{summary.percentBlocked || 0}% of ad visitors</p>
                    <p>
                      <Link className={styles.link} to="/stats">
                        View Score Details
                      </Link>
                    </p>
                  </div>
                </div>
              </div>

              <div style={customStyles.totalSavedFeatured} className={styles.featured}>
                <div className={styles.featuredInner}>
                  <div className={styles.featureIcon}>
                    <img src={totalSaved.image} />
                  </div>
                  <div className={styles.featuredDescriptions}>
                    <p className={styles.featuredTitle}>
                      {editing ? 'Edit CPC Cost' : 'Total Saved'}
                      {!editing && (
                        <a data-tip data-for="totalSaved">
                          <img className={styles.tooltip} src={TOOLTIP} />
                        </a>
                      )}
                    </p>
                    {!editing && (
                      <p className={styles.featuredTotal}>
                        {Utils.convertToCurrency(
                          conversionRates,
                          Math.round((summary.blockedClicks || 0) * (activeDomain.data.cpc || 2)),
                          currency
                        )}
                      </p>
                    )}
                    {!editing && (
                      <p>
                        {summary.blockedClicks || 0} clicks x{' '}
                        {Utils.convertToCurrency(
                          conversionRates,
                          activeDomain.data.cpc || 2,
                          currency,
                          true,
                          true
                        )}{' '}
                        CPC avg.
                      </p>
                    )}
                    {editing && (
                      <>
                        <Input
                          style={customStyles.cpcInput}
                          containerStyle={customStyles.cpcContainer}
                          name="cpc"
                          onChange={this.handleCPC}
                          value={cpc}
                          error={!this.isValidCPC(cpc) ? 'Enter a numeric value' : ''}
                          errorStyle={customStyles.cpcError}
                        />
                        <span style={customStyles.cpcLabel}>CPC Cost</span>
                      </>
                    )}
                    <div className={styles.cpcCurrencyWrap}>
                      <p className={styles.link} onClick={this.enableEdit}>
                        {editing ? 'Save CPC value' : 'Edit CPC value'}
                      </p>
                      <p className={styles.link}>
                        <Link to="/account/settings/edit-profile">Change currency</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featured}>
                <div className={styles.featuredInner}>
                  <div className={styles.featureIcon}>
                    <img src={fraudScore.image} />
                  </div>
                  <div className={styles.featuredDescriptions}>
                    <p className={styles.featuredTitle}>Fraud Score</p>
                    <p className={styles.featuredTotal}>
                      {Utils.calcFraudScore(summary.percentBlocked, 1)}
                    </p>
                    <p>Out of 10</p>
                    <p>
                      <Link className={styles.link} to="/stats">
                        View score details
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.featuredContainer}>
              <div className={styles.statsLoading}>
                <div className={styles.stats1} />
                <div className={styles.stats2} />
                <div className={styles.stats3} />
              </div>
            </div>
          )}

          {!fetchingChart ? (
            <div className={styles.chartContainer}>
              <div className={styles.legendContainer}>
                <div className={styles.legend}>
                  <div className={styles.blockedIPsMask} />
                  <p className={styles.legendText}>Invalid Clicks</p>
                </div>
                <div className={styles.legend}>
                  <div className={styles.visitorsFromAdsMask} />
                  <p className={styles.legendText}>Visits From Ads</p>
                </div>
              </div>
              {chart && chart.length && chart.some(item => item.uv || item.pv) ? (
                <Chart data={chart} />
              ) : !summary.clicks && connected ? (
                <div style={{ textAlign: 'center', color: 'rgba(0,0,0,0.5)' }}>
                  <img src={ANALYZING} />
                  {this.state.adType === 'msclkid' ? (
                    <div style={{ marginTop: '20px', color: '#a7b3c0', fontSize: '16px' }}>
                      We do not currently detect any visitors to your website from Microsoft Ads.
                    </div>
                  ) : (
                    <div style={{ marginTop: '20px', color: '#a7b3c0', fontSize: '16px' }}>
                      Your Google Ads account is connected and we are analyzing your data.
                      <br />
                      Come back soon.
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ textAlign: 'center', color: 'rgba(0,0,0,0.5)' }}>
                  <img src={EMPTY_REPORT} />
                  <div style={{ marginTop: '20px', color: '#a7b3c0', fontSize: '16px' }}>
                    No traffic from advertising detected.
                    <br />
                    <Link
                      style={{ color: '#a7b3c0', fontSize: '16px' }}
                      onClick={() => {
                        console.log(233);
                      }}
                      to="/integrations"
                    >
                      Verify your Fraud Tracker installation.
                    </Link>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.chartLoading} />
          )}

          {!fetchingOrganic && !fetchingSummary ? (
            <div className={styles.featuredContainer}>
              <div style={customStyles.frontSubFeatured} className={styles.subFeatured}>
                <img src={OrganicVisitors} />
                <div className={styles.subFeaturedDescriptions}>
                  <p className={styles.subFeaturedTotal}>
                    {(organicStats.allViews || 0).toLocaleString('en-US', {
                      maximumFractionDigits: 1
                    })}
                  </p>
                  <p>Total Visitors</p>
                </div>
              </div>
              <div className={styles.divider} />
              <div style={customStyles.middleSubFeatured} className={styles.subFeatured}>
                <img src={VisitorsAdvertising} />
                <div className={styles.subFeaturedDescriptions}>
                  <p className={styles.subFeaturedTotal}>
                    {(summary.clicks || 0).toLocaleString('en-US', { maximumFractionDigits: 1 })}
                  </p>
                  <p>Visits From Ads</p>
                </div>
              </div>
              <div className={styles.divider} />
              <div style={customStyles.middleSubFeatured} className={styles.subFeatured}>
                <img src={FraudDetectionRate} />
                <div className={styles.subFeaturedDescriptions}>
                  <p className={styles.subFeaturedTotal}>
                    {organicStats.allViews
                      ? roundAmount((summary.clicks * 100) / organicStats.allViews, 1)
                      : 0}
                    %
                  </p>
                  <p>Visits From Ads</p>
                </div>
              </div>
              <div className={styles.divider} />
              <div style={customStyles.endSubFeatured} className={styles.subFeatured}>
                <img src={AdsClickRate} />
                <div className={styles.subFeaturedDescriptions}>
                  <p className={styles.subFeaturedTotal}>
                    {summary.percentBlocked
                      ? summary.percentBlocked.toLocaleString('en-US', { maximumFractionDigits: 1 })
                      : 0}
                    %
                  </p>
                  <p>Invalid Traffic (Estimated)</p>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.featuredContainer}>
              <div className={styles.bottomStatsLoading} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  accounts: PropTypes.object,
  activeDomain: PropTypes.object,
  updateDomain: PropTypes.func,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  accounts: state.accounts,
  activeDomain: state.activeDomain,
  auth: state.auth
});

const mapDispatchToProps = dispatch => {
  return {
    updateDomain: (id, payload) => dispatch(ActiveDomain.updateDomain(id, payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
