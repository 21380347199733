import React, { PureComponent } from 'react';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import { connect } from 'react-redux';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import styles from './Integrations.module.scss';
import Button from '../../components/Button/Button';
import GoogleAds from '../../api/GoogleAds';
// import SuccessBox from '../../components/SuccessBox/SuccessBox';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import ActiveDomain from '../../redux/actions/ActiveDomain';
import Input from '../../components/Input/Input';
import Account from '../../redux/actions/Account';
import DriveIcon from '../../assets/googleconnected.svg';
import ArrowRight from '../../assets/dropdown-arrow.svg';
import CheckIcon from '../../assets/connected.svg';
import ConnectionErrorIcon from '../../assets/google-connection-error.svg';
import GoogleIcon from '../../assets/google-logo.svg';

const customStyles = {
  subTitle: {
    marginTop: 40
  },
  connectBtn: {
    maxWidth: 350,
    marginTop: 20,
    marginBottom: 20
  },
  removeConnectionGrayButton: {
    border: 'none',
    background: 'transparent',
    color: '#7e7e7e',
    fontWeight: 'normal'
  },
  divider: {
    marginTop: 20,
    width: '100%',
    height: 1,
    backgroundColor: '#d5d9de'
  },
  invited: {
    color: '#0caf1d',
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: '10px'
  },
  mccInput: {
    width: '90px',
    height: '36px',
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'center',
    paddingLeft: '18px',
    paddingRight: '18px'
  }
};
class GoogleAdsSetup extends PureComponent {
  state = {
    loading: {},
    success: {},
    status: 'all',
    email: '',
    accountName: '',
    error: {},
    clients: [],
    filteredClients: [],
    invited: false,
    managerAccountId: '',
    showMangerDesc: false,
    disconnectingAds: false
  };

  sendInvitation = async () => {
    const { activeDomain } = this.props;
    if (
      !this.state.managerAccountId ||
      !activeDomain ||
      !activeDomain.data ||
      !activeDomain.data.id
    ) {
      return null;
    }
    try {
      this.setState({ loading: { ...this.state.loading, manager: true } });
      const inviteResult = await GoogleAds.inviteManagerAccount({
        managerId: this.state.managerAccountId.replace(/-/g, ''),
        domainId: activeDomain.data.id
      });
      console.log('Invitation Result', inviteResult);
      this.setState({
        loading: { ...this.state.loading, manager: false },
        invited: true
      });
      return null;
    } catch (error) {
      this.setState({
        error: { manager: error.message },
        loading: { ...this.state.loading, manager: false }
      });
      return null;
    }
  };

  handleManagerAccountChange = e => {
    this.setState({ managerAccountId: e.target.value });
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  applyFilters = clients => {
    if (!clients.length) {
      return [];
    }
    const filtered = clients.filter(client => {
      const { accountName, status } = this.state;
      return (
        (client.customerClient.descriptiveName || '')
          .toLowerCase()
          .includes(accountName.toLowerCase()) &&
        (status === 'all' ? true : status === 'connected' ? client.connected : !client.connected)
      );
    });
    this.setState({
      filteredClients: filtered
    });
    return null;
  };

  handleAccountNameFilterChange = e => {
    this.setState({ accountName: e.target.value }, () => this.applyFilters(this.state.clients));
  };

  handleStatusFilterChange = e => {
    this.setState({ status: e.target.value }, () => this.applyFilters(this.state.clients));
  };

  getAndSetCustomerClients = async (accountId, domainId) => {
    try {
      this.setState({ loading: { ...this.state.loading, clients: true } });
      const clientsResult = await GoogleAds.getCustomerClients(accountId, domainId);
      console.log('Connected Clients', clientsResult);
      this.setState({
        clients: clientsResult,
        loading: { ...this.state.loading, clients: false }
      });
      this.applyFilters(clientsResult);
    } catch (error) {
      this.setState({
        error: { authorize: error.message },
        loading: { ...this.state.loading, clients: false }
      });
    }
  };

  onGoogleLoginResponse = async response => {
    const { accounts, setDomain, activeDomain, fetchLatestAccount } = this.props;

    console.log(response);
    if (response.code) {
      try {
        console.log(response);
        const authUserResult = await GoogleAds.authorizeUser(
          response.code,
          activeDomain.data.id,
          accounts.data.id
        );
        console.log(authUserResult);
        if (authUserResult) {
          window.Intercom('trackEvent', 'connect_oauth', {
            domain: activeDomain.data.domain_name
          });
          this.getAndSetCustomerClients(accounts.data.id, activeDomain.data.id);
          fetchLatestAccount(accounts.data.id);
          setDomain({ ...activeDomain.data, google_ads_token: authUserResult.refresh_token });
        }
      } catch (error) {
        console.log(error.message);
        this.setState({ error: { authorize: error.message } });
      }
    }
    if (response.error) {
      console.error(response);
    }
  };

  componentDidMount = () => {
    if (
      this.props.accounts &&
      this.props.accounts.data &&
      this.props.accounts.data.id &&
      this.props.activeDomain &&
      this.props.activeDomain.data &&
      this.props.activeDomain.data.id &&
      this.props.activeDomain.data.google_ads_token
    ) {
      this.getAndSetCustomerClients(this.props.accounts.data.id, this.props.activeDomain.data.id);
    }
  };

  componentDidUpdate = preProps => {
    if (
      this.props.activeDomain &&
      this.props.activeDomain.data &&
      preProps.activeDomain &&
      preProps.activeDomain.data &&
      preProps.activeDomain.data.id !== this.props.activeDomain.data.id
    ) {
      this.setState({ clients: [], error: {}, success: {}, loading: {} });
      if (this.props.activeDomain.data.google_ads_token) {
        this.getAndSetCustomerClients(this.props.accounts.data.id, this.props.activeDomain.data.id);
      }
    }
  };

  testGoogleAdsIntegration = async () => {
    const { activeDomain } = this.props;
    this.setState({ loading: { test: true } });
    try {
      const result = await GoogleAds.testGoogleAdsIntegration();
      if (result === 'Unauthorized') {
        await GoogleAds.refreshAccessToken(activeDomain.data.id);
        this.testGoogleAdsIntegration();
      } else {
        const { resourceNames } = result;
        if (resourceNames) {
          this.setState({ success: { test: true }, error: {}, loading: { test: false } });
        } else {
          throw Error(`No Accounts found.`);
        }
      }
    } catch (error) {
      console.log(error.message);
      this.setState({ success: {}, error: { test: error.message }, loading: { test: false } });
    }
  };

  disconnectClient = async client => {
    const { loading } = this.state;
    if (loading.disconnect) {
      return;
    }
    const { accounts, activeDomain } = this.props;
    this.setState({ loading: { disconnect: true }, disconnected: false });
    try {
      await GoogleAds.disconnectClient(client.recordId);
      this.setState({ disconnected: true });
      setTimeout(() => {
        this.setState({
          success: { disconnect: true },
          error: {},
          loading: { disconnect: false },
          clients: []
        });
        this.getAndSetCustomerClients(accounts.data.id, activeDomain.data.id);
      }, 1500);
    } catch (error) {
      console.log(error.message);
      this.setState({
        success: {},
        error: { disconnect: error.message },
        loading: { disconnect: false }
      });
    }
  };

  disconnectDomain = async () => {
    console.log(this.props);
    const { loading } = this.state;
    if (loading.disconnect) {
      return;
    }

    const { accounts, activeDomain, fetchLatestAccount, setDomain } = this.props;
    this.setState({ loading: { disconnect: true } });
    try {
      await GoogleAds.disconnectDomain(activeDomain.data.id);
      fetchLatestAccount(accounts.data.id);
      this.setState({
        loading: { disconnect: false },
        error: {}
      });
      setDomain({ ...activeDomain.data, google_ads_token: null, google_email: null });
    } catch (error) {
      console.log(error.message);
      this.setState({
        success: {},
        error: { disconnectDomain: error.message },
        loading: { disconnect: false }
      });
    }
  };

  disconnectGoogleAds = async (clearConnection = false) => {
    console.log(this.props);
    const { loading } = this.state;
    if (loading.disconnectingAds) {
      return;
    }

    const { accounts, activeDomain, fetchLatestAccount, setDomain, auth } = this.props;
    this.setState({ loading: { disconnectingAds: true } });
    try {
      if (clearConnection) {
        await GoogleAds.clearConnection(activeDomain.data.id);
      } else {
        await GoogleAds.disconnectGoogleAds(this.state.email || auth.user.email);
      }
      fetchLatestAccount(accounts.data.id);
      this.setState({
        loading: { disconnectingAds: false },
        error: {}
      });
      if (clearConnection) {
        setDomain({
          ...activeDomain.data,
          google_ads_token: null,
          google_email: null,
          oauth_problem: false,
          mcc_manager_id: null,
          mcc_link_id: null
        });
      } else {
        setDomain({ ...activeDomain.data, google_ads_token: null, google_email: null });
      }
    } catch (error) {
      console.log(error.message);
      this.setState({
        success: {},
        error: { disconnectDomain: error.message },
        loading: { disconnectingAds: false }
      });
    }
  };

  connectAccount = async client => {
    const { loading } = this.state;
    if (loading.connect) {
      return;
    }
    const { accounts, activeDomain } = this.props;
    this.setState({ loading: { connect: `${client.customerClient.id}${client.managerId}` } });
    try {
      await GoogleAds.connectClient({
        customer_account: client.customerClient,
        account_id: accounts.data.id,
        domain_id: activeDomain.data.id,
        customer_id: client.customerId,
        manager_id: client.managerId
      });
      setTimeout(() => {
        this.setState({ success: { connect: true }, error: {}, loading: { connect: false } });
        this.getAndSetCustomerClients(accounts.data.id, activeDomain.data.id);
        window.Intercom('trackEvent', 'connect_adwords', {
          domain: activeDomain.data.domain_name
        });
      }, 2000);
    } catch (error) {
      console.log(error.message);
      this.setState({
        success: {},
        error: { connect: error.message },
        loading: { connect: false }
      });
    }
  };

  openManagerDesc = () => {
    this.setState({
      showMangerDesc: !this.state.showMangerDesc
    });
  };

  render() {
    const {
      loading,
      // success,
      // disconnected,
      error,
      clients,
      managerAccountId,
      filteredClients,
      accountName,
      status
    } = this.state;
    const { activeDomain, auth } = this.props;
    const connected = activeDomain && activeDomain.data && activeDomain.data.google_ads_token;

    return (
      <div className={styles.content}>
        <h1 className={styles.title}>Google Ads Setup</h1>
        <p>
          In order for us to block the fraudulent clicks coming from your Google Ads campaigns, you
          will need to provide us access to your Google Ads account so we can import any fraudulent
          IP addresses in real time.
        </p>
        <br />
        {!connected && (
          <>
            <h3 style={customStyles.subTitle} className={styles.subTitle}>
              Connect Google Ads
            </h3>
            <p>
              Press the button below to send a request to Google Ads for us to access your account.
            </p>
            <div className={styles.googleLoginContainer}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                scope="https://www.googleapis.com/auth/adwords"
                theme="dark"
                responseType="code"
                accessType="offline"
                redirectUri={`${process.env.REACT_APP_HOST}/integrations/google-ads-setup`}
                onSuccess={this.onGoogleLoginResponse}
                onFailure={this.onGoogleLoginResponse}
                render={renderProps => (
                  <Button
                    customClassNames={styles.googleButton}
                    title={
                      <div>
                        <span className={styles.googleIconBg}>
                          <img
                            style={{
                              verticalAlign: 'middle',
                              width: '14px',
                              height: '14px'
                            }}
                            src={GoogleIcon}
                            alt="google-icon"
                          />
                        </span>
                        <span className={styles.signInText}>Sign In With Google</span>
                      </div>
                    }
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  />
                )}
              />
            </div>
            {error.authorize && <ErrorBox error={error.authorize} />}

            {/* {connected && (
          <div className={styles.runTestContainer}>
            <Button
              title="Test Integration"
              color="outline"
              loading={loading.test}
              onClick={this.testGoogleAdsIntegration}
              disabled={false}
            />
            {success.test && <SuccessBox message="Success" />}
            {error.test && <ErrorBox error={error.test} />}
          </div>
        )} */}

            {/* <div style={customStyles.divider} /> */}
          </>
        )}

        {connected && clients.length === 0 && !loading.clients && !error.connect && (
          <>
            <h3 style={customStyles.subTitle} className={styles.errorMessageTitle}>
              <img src={ConnectionErrorIcon} alt="warning" className={styles.connectionErrorIcon} />{' '}
              There is an issue with your Google Ads connection
            </h3>
            <p>
              We were unable to fetch your Google Ads customer account with the authorization
              provided. This means that we cannot sync your blocked IPs to your campaigns. This may
              have been any issue with your cache. Please remove your connection and try again.
            </p>
            <Button
              customClassNames={styles.removeConnectionBtn}
              title="Remove Connection"
              color="outline-red"
              loading={loading.disconnectingAds}
              onClick={() => this.disconnectGoogleAds(true)}
            />
            <p style={{ marginBottom: '20px' }}>
              Still have trouble?{' '}
              <a
                className={styles.blueLink}
                href="https://fraudblocker.com/contact-us"
                rel="noreferrer noopener"
                target="_blank"
              >
                Contact us.
              </a>
            </p>
            {error.disconnectDomain && <ErrorBox error={error.disconnectDomain} />}
          </>
        )}

        {!loading.clients && connected && clients.length > 0 && (
          <>
            <div className={styles.accountsHeader}>
              <h3 style={customStyles.subTitle} className={styles.subTitle}>
                Available Accounts
              </h3>
              <div className={styles.searchWrap}>
                <Input
                  placeholder="Account Name"
                  onChange={this.handleAccountNameFilterChange}
                  value={accountName}
                />
                <ToggleButtonGroup
                  className={styles.filtersToggleGroup}
                  color="primary"
                  value={status}
                  exclusive
                  onChange={this.handleStatusFilterChange}
                  aria-label="Status"
                >
                  <ToggleButton value="all">Show All</ToggleButton>
                  <ToggleButton value="connected">Connected</ToggleButton>
                  <ToggleButton value="disconnected">Not Connected</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            {filteredClients.map((client, index) => (
              <div
                className={
                  loading.connect === `${client.customerClient.id}${client.managerId}`
                    ? styles.fadingIn
                    : ''
                }
                key={`${client.customerClient.id}${client.managerId}`}
                style={{
                  animationDelay:
                    loading.connect === `${client.customerClient.id}${client.managerId}`
                      ? '0.3s'
                      : `${(index + 1) * 0.3}s`
                }}
              >
                <div className={styles.connectedWrap}>
                  <img src={DriveIcon} alt="drive icon" className={styles.driveIcon} />
                  <div className={styles.connectedText}>
                    <div className={styles.connectedEmail}>
                      {client.customerClient.descriptiveName}
                    </div>
                    <div className={styles.connected}>{client.id || client.customerClient.id}</div>
                  </div>
                  {client.connected ? (
                    <div className={styles.connectedRight}>
                      <div className={styles.connectedBtn}>
                        <img src={CheckIcon} alt="connected" />
                        Connected
                      </div>
                      <div className={styles.disconnectWrap}>
                        <div
                          onClick={() => this.disconnectClient(client)}
                          className={styles.disconnectBtn}
                        >
                          {loading.disconnect ? 'Disconnecting...' : 'Disconnect This Account'}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div onClick={() => this.connectAccount(client)} className={styles.connectBtn}>
                      {loading.connect === `${client.customerClient.id}${client.managerId}` ? (
                        <Spinner fadeIn="none" name="ball-clip-rotate" color="#17d281" />
                      ) : (
                        'Connect This Account'
                      )}
                    </div>
                  )}
                </div>
                <div style={customStyles.divider} />
              </div>
            ))}
            {/* For logged in user so that they can reset the connection */}
            <div className={styles.removeConnectionBlock}>
              <Button
                onClick={this.disconnectGoogleAds}
                disabled={loading.disconnectingAds}
                style={customStyles.removeConnectionGrayButton}
                type="button"
                color="transparent"
                title="Remove Google Connection"
              />
            </div>
          </>
        )}

        {loading.clients && (
          <div className={`${styles.clientsLoader} ${styles.fadingIn}`}>
            <Spinner fadeIn="linear" name="ball-clip-rotate" color="#17d281" />
          </div>
        )}

        {error.connect && <ErrorBox error={error.connect} />}
        {error.disconnect && <ErrorBox error={error.disconnect} />}
        {error.connect && (
          <div className={styles.disconnectWrap}>
            <div
              onClick={() => this.disconnectDomain()}
              className={styles.disconnectBtn}
              style={{ marginLeft: 0 }}
            >
              {loading.disconnect ? 'Removing' : 'Remove'} Google oAuth Connection
            </div>
          </div>
        )}
        {error.connect && error.disconnectDomain && <ErrorBox error={error.disconnectDomain} />}
        {/* visible to the god mode only */}
        {auth.user && auth.user.id === 'jqsRU2kZc4R2UKXtp88QCLnwUUp1' && (
          <div>
            <h4>Delete Google Ads Data</h4>
            <div
              style={{ display: 'flex', width: '300px', marginTop: '20px', marginBottom: '20px' }}
            >
              <Input
                onChange={this.handleEmailChange}
                value={this.state.email}
                type="text"
                style={{ marginRight: '10px', height: '36px' }}
                placeholder="Email"
              />
              <Button
                onClick={this.disconnectGoogleAds}
                disabled={!this.state.email || loading.disconnectingAds}
                type="button"
                color="red"
                title="Delete"
              />
              <Button
                style={{ marginLeft: '10px', minWidth: '280px' }}
                onClick={this.disconnectDomain}
                disabled={loading.disconnect}
                type="button"
                color="red"
                title="Delete Google Ads Data (domain level)"
              />
            </div>
            <p>
              The Delete button will delete the oAuth email and the Google Ads Account numbers for
              all domain names under the email account provided.
            </p>
          </div>
        )}

        <div className={styles.managerAdsSec}>
          <div className={styles.secHeading} onClick={this.openManagerDesc}>
            For “Manager” Accounts (“MCC”)
            <span className={`${styles.icon} ${this.state.showMangerDesc ? 'active' : ''}`}>
              <img src={ArrowRight} />
            </span>
          </div>
          <div className={`${styles.managerAdsDesc} ${this.state.showMangerDesc ? 'active' : ''}`}>
            <div className={styles.managerAdsInner}>
              <p>
                You must first join our Manager Account before you can connect to your customer
                accounts. Please send and invite by entering your MCC Account number below.
              </p>
              <div className={styles.mangerAccountForm}>
                <Input
                  onChange={this.handleManagerAccountChange}
                  value={managerAccountId}
                  type="text"
                  placeholder="000-000-0000"
                  mask="999-999-9999"
                  maskChar="_"
                  style={customStyles.mccInput}
                />
                <button
                  onClick={this.sendInvitation}
                  disabled={loading.manager || this.state.invited}
                  type="button"
                >
                  Send Manager Invite
                </button>
                {this.state.invited && <span style={customStyles.invited}>✓ Invitation Sent</span>}
              </div>
              {error.manager && <ErrorBox error={error.manager} />}
              <p className={styles.noMargin}>
                Next, accept the invite by going to your MCC Account (instructions are
                available&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://help.fraudblocker.com/en/collections/1818202-help-and-answers"
                >
                  here
                </a>
                )
                <br />
                Once accepted, press the &quot;Sign in with Google&quot; button and select the
                Google Ads account you wish to connect to.
              </p>
            </div>
          </div>
        </div>
        <h3 style={customStyles.subTitle} className={styles.subTitle}>
          Why We Need This
        </h3>
        <p>
          By providing us access to your Google Ads account, we can import any fradulent IP
          Addresses we detect directly to your Google Ads account in real time. Without this access
          you will be unable to automatically block bad clicks from your account. Our software does
          not not edit or change any other elements of your Google Ad campaigns.
        </p>

        <h3 style={customStyles.subTitle} className={styles.subTitle}>
          About Your Security
        </h3>
        <p>
          Our mission is to protect our clients from the rampant ad fraud occurring on the internet
          today. We also aim to provide more transparency with our service with access to detailed
          fraud reporting and analytics. We have taken steps and put security measures in place to
          prevent the accidental loss or misuse of personal data, and will take all steps possible
          to make sure that your personal data is encrypted and stored securely. You can read more
          about our security methods in our{' '}
          <a href="https://fraudblocker.com/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          .
        </p>
      </div>
    );
  }
}

GoogleAdsSetup.propTypes = {
  accounts: PropTypes.object,
  setDomain: PropTypes.func,
  auth: PropTypes.object,
  activeDomain: PropTypes.object,
  fetchLatestAccount: PropTypes.func
};

const mapStateToProps = state => ({
  accounts: state.accounts,
  auth: state.auth,
  activeDomain: state.activeDomain
});

const mapDispatchToProps = dispatch => {
  return {
    setDomain: domain => dispatch(ActiveDomain.setDomainActive(domain)),
    fetchLatestAccount: accountId => dispatch(Account.fetchLatestAccount(accountId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAdsSetup);
