import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './Customizations.module.scss';
import Button from '../../components/Button/Button';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import SuccessBox from '../../components/SuccessBox/SuccessBox';
import IPBlockList from '../../redux/actions/IpBlockList';
import Validation from '../../utils/Validation';
import GoogleAds from '../../api/GoogleAds';

const customStyles = {
  saveBtn: {
    maxWidth: 110,
    marginBottom: 60
  }
};
class IPBlocking extends PureComponent {
  constructor(props) {
    super(props);

    const { ipBlocklist = [], ipWhitelist = [] } = props;
    const ipBlocks = ipBlocklist.map(ipBlock => ipBlock.address);
    const whiteIps = ipWhitelist.map(ip => ip.address);

    this.state = {
      originalIpBlocks: ipBlocks,
      ipBlacklist: ipBlocks.join('\n') || '',
      originalWhiteIps: whiteIps,
      whiteIpslist: whiteIps.join('\n') || '',
      ipWhitelist: '',
      errors: {},
      saveSuccess: {},
      loading: {}
    };
  }

  componentDidUpdate = preProps => {
    if (
      this.props.activeDomain &&
      this.props.activeDomain.data &&
      preProps.activeDomain &&
      preProps.activeDomain.data &&
      preProps.activeDomain.data.id !== this.props.activeDomain.data.id
    ) {
      this.setState({
        originalIpBlocks: [],
        ipBlacklist: '',
        originalWhiteIps: [],
        whiteIpslist: '',
        ipWhitelist: '',
        errors: {},
        saveSuccess: {},
        loading: {}
      });
      this.fetchBlockedIPs();
    }
  };

  componentDidMount() {
    const { activeDomain } = this.props;
    if (activeDomain.data && activeDomain.data.id) {
      this.fetchBlockedIPs();
    }
  }

  // componentDidUpdate(preProps) {
  //   const { accounts, activeDomain, ipBlocklist, ipWhitelist } = this.props;
  //   if (
  //     activeDomain.data &&
  //     prePropsactiveDomain.data.id &&
  //     (!ipBlocklist.length || !ipWhitelist.length)
  //   ) {
  //     this.fetchBlockedIPs();
  //   }
  // }

  fetchBlockedIPs = async () => {
    const { activeDomain, fetchLatestBlocklist } = this.props;
    try {
      const result = await fetchLatestBlocklist(activeDomain.data.id);
      if (result) {
        const ipBlocks = result
          .filter(ip => ip.is_blocked === true)
          .map(ipBlock => ipBlock.address);
        const ipsWhite = result
          .filter(ip => ip.is_blocked === false)
          .map(ipBlock => ipBlock.address);
        this.setState({ ipBlacklist: ipBlocks.join('\n'), originalIpBlocks: ipBlocks });
        this.setState({ whiteIpslist: ipsWhite.join('\n'), originalWhiteIps: ipsWhite });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  onInputChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSaveIPBlacklist = async () => {
    this.setState({ errors: {}, loading: { blocklist: true } });
    const { accounts, ipBlocklist, ipWhitelist, activeDomain } = this.props;
    const { ipBlacklist, originalIpBlocks } = this.state;

    const hasErrors = [];
    const ipBlacklistCombined = ipBlacklist
      ? ipBlacklist.split('\n').filter(item => item.trim() !== '')
      : [];
    ipBlacklistCombined.forEach(ipBlock => {
      const isValid = Validation.validIpAddress(ipBlock.trim());
      if (!isValid) {
        hasErrors.push(ipBlock);
      }
    });

    if (hasErrors.length > 0) {
      console.log('Invalid');
      this.setState({
        loading: { blocklist: false },
        errors: {
          blockError: `Invalid IP Addresses Found: [${hasErrors.join('\n')}]`
        }
      });
      return;
    }

    console.log('All Valid.');
    const additional = ipBlacklistCombined.filter(ipAddress => {
      return !originalIpBlocks.some(ogIpAddress => {
        return ipAddress.trim() === ogIpAddress;
      });
    });

    console.log('Additional: ', additional);

    const removals = originalIpBlocks.filter(ogIpAddress => {
      return !ipBlacklistCombined.some(ipAddress => {
        return ipAddress.trim() === ogIpAddress;
      });
    });

    const removalIpBlocks = [];
    removals.forEach(ip => {
      const ipWhite = ipBlocklist.find(item => item.address === ip.trim());
      if (ipWhite) {
        removalIpBlocks.push(ipWhite.id);
      }
    });

    if (originalIpBlocks.length !== ipBlacklistCombined.length) {
      for (let i = 0; i < originalIpBlocks.length; i += 1) {
        const originalItemsLength = originalIpBlocks.filter(ip => ip === originalIpBlocks[i])
          .length;
        const newItemsLength = ipBlacklistCombined.filter(ip => ip === ipBlacklistCombined[i])
          .length;
        if (
          newItemsLength &&
          originalItemsLength > newItemsLength &&
          removals.filter(item => item === originalIpBlocks[i]).length <
            originalItemsLength - newItemsLength
        ) {
          removals.push(originalIpBlocks[i]);
          removalIpBlocks.push(ipBlocklist[i].id);
        }
      }
    }

    console.log('Removals', removalIpBlocks);
    const addIpBlocks = [...new Set(additional)].map(addIp => {
      return {
        account_id: accounts.data.id,
        address: addIp.trim(),
        domain_id: activeDomain.data.id,
        is_blocked: true
      };
    });

    const removalIpWhite = [];
    additional.forEach(ip => {
      const ipWhite = ipWhitelist.find(item => item.address === ip.trim());
      if (ipWhite) {
        removalIpWhite.push(ipWhite.id);
      }
    });

    try {
      if (removalIpWhite.length > 0) {
        await GoogleAds.removeIpFromBlocklist({ ids: removalIpWhite });
      }
      if (addIpBlocks.length > 0) {
        await GoogleAds.addIpToBlocklist({ ips: addIpBlocks });
      }
      if (removals.length > 0) {
        await GoogleAds.removeIpFromBlocklist({ ids: removalIpBlocks });
      }
      await this.fetchBlockedIPs();
      this.setState({ saveSuccess: { blocklist: true }, loading: { blocklist: false } });
    } catch (error) {
      this.setState({
        saveSuccess: {},
        errors: { blockError: error.message },
        loading: { blocklist: false }
      });
    }
  };

  onSaveIPWhitelist = async () => {
    this.setState({
      errors: { ...this.state.errors, whiteError: '' },
      loading: { ...this.state.loading, whitelist: true }
    });
    const { accounts, ipWhitelist, ipBlocklist, activeDomain } = this.props;
    const { whiteIpslist, originalWhiteIps } = this.state;

    const hasErrors = [];
    const combined = whiteIpslist
      ? whiteIpslist.split('\n').filter(item => item.trim() !== '')
      : [];
    combined.forEach(ip => {
      const isValid = Validation.validIpAddress(ip.trim());
      if (!isValid) {
        hasErrors.push(ip);
      }
    });

    if (hasErrors.length > 0) {
      console.log('Invalid');
      this.setState({
        loading: { ...this.state.loading, whitelist: false },
        errors: {
          ...this.state.error,
          whiteError: `Invalid IP Addresses Found: [${hasErrors.join('\n')}]`
        }
      });
      return;
    }

    console.log('All Valid.');
    const additional = combined.filter(ipAddress => {
      return !originalWhiteIps.some(ogIpAddress => {
        return ipAddress.trim() === ogIpAddress;
      });
    });

    console.log('Additional: ', additional);

    const removals = originalWhiteIps.filter(ogIpAddress => {
      return !combined.some(ipAddress => {
        return ipAddress.trim() === ogIpAddress;
      });
    });

    const removalIps = [];
    removals.forEach(ip => {
      const ipWhite = ipWhitelist.find(item => item.address === ip.trim());
      if (ipWhite) {
        removalIps.push(ipWhite.id);
      }
    });

    if (originalWhiteIps.length !== combined.length) {
      for (let i = 0; i < originalWhiteIps.length; i += 1) {
        const originalItemLength = originalWhiteIps.filter(ip => ip === originalWhiteIps[i]).length;
        const newItemLength = combined.filter(ip => ip === combined[i]).length;
        if (
          newItemLength &&
          originalItemLength > newItemLength &&
          removals.filter(item => item === originalWhiteIps[i]).length <
            originalItemLength - newItemLength
        ) {
          removals.push(originalWhiteIps[i]);
          removalIps.push(ipWhitelist[i].id);
        }
      }
    }

    console.log('Removals', removalIps);

    const removalWhite = [];
    additional.forEach(ip => {
      const ipWhite = ipBlocklist.find(item => item.address === ip.trim());
      if (ipWhite) {
        removalWhite.push(ipWhite.id);
      }
    });

    const addIps = [...new Set(additional)].map(addIp => {
      return {
        account_id: accounts.data.id,
        address: addIp.trim(),
        domain_id: activeDomain.data.id,
        is_blocked: false
      };
    });

    try {
      if (removalWhite.length > 0) {
        await GoogleAds.removeIpFromBlocklist({ ids: removalWhite });
      }
      if (addIps.length > 0) {
        await GoogleAds.addIpToBlocklist({ ips: addIps });
      }
      if (removals.length > 0) {
        await GoogleAds.removeIpFromBlocklist({ ids: removalIps });
      }
      await this.fetchBlockedIPs();
      this.setState({ saveSuccess: { whitelist: true }, loading: { whitelist: false } });
    } catch (error) {
      this.setState({
        saveSuccess: {},
        errors: { whiteError: error.message },
        loading: { whitelist: false }
      });
    }
  };

  render() {
    const { whiteIpslist, ipBlacklist, errors, saveSuccess, loading } = this.state;

    return (
      <div className={styles.content}>
        <h1 className={styles.title}>Manage IP Blocking</h1>
        <h3 className={styles.subTitle}>IP Blacklist</h3>
        <p>
          Add IPs in the box below that you want Fraud Blocker to always block (such as internal
          staff IPs). Each IP address must be in a new line and you can designate a range of IPs by
          adding a wildcard character (*) or CIDR notation (example: 218.100.46.0/23 and
          196.10.252*).
        </p>

        <textarea
          className={styles.textarea}
          value={ipBlacklist}
          name="ipBlacklist"
          onChange={this.onInputChange}
        />
        {saveSuccess.blocklist && <SuccessBox message="Save Successful" />}
        {errors.blockError && <ErrorBox error={errors.blockError} />}
        <Button
          title="Save"
          loading={loading.blocklist}
          onClick={this.onSaveIPBlacklist}
          style={customStyles.saveBtn}
        />
        <h3 className={styles.subTitle}>IP Whitelist</h3>
        <p>
          Add IPs in the box below that you want Fraud Blocker never to block. Each IP address must
          be in a new line and you can designate a range of IPs by adding a wildcard character (*)
          or CIDR notation (example: 218.100.46.0/23 and 196.10.252*).
        </p>

        <textarea
          className={styles.textarea}
          value={whiteIpslist}
          name="whiteIpslist"
          onChange={this.onInputChange}
        />
        {saveSuccess.whitelist && <SuccessBox message="Save Successful" />}
        {errors.whiteError && <ErrorBox error={errors.whiteError} />}
        <Button
          title="Save"
          color="blue"
          onClick={this.onSaveIPWhitelist}
          style={customStyles.saveBtn}
          loading={loading.whitelist}
        />
      </div>
    );
  }
}

IPBlocking.propTypes = {
  accounts: PropTypes.object,
  activeDomain: PropTypes.object,
  ipBlocklist: PropTypes.array,
  ipWhitelist: PropTypes.array,
  fetchLatestBlocklist: PropTypes.func
};

const mapStateToProps = state => ({
  accounts: state.accounts,
  ipBlocklist: state.ipBlocklist.data,
  ipWhitelist: state.ipBlocklist.whiteIPs,
  activeDomain: state.activeDomain
});

const mapDispatchToProps = dispatch => {
  return {
    fetchLatestBlocklist: domainId => dispatch(IPBlockList.fetchLatestBlocklist(domainId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IPBlocking);
