import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styles from './Account.module.scss';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Dropdown from '../../components/Dropdown/Dropdown';
import User from '../../redux/actions/User';
import Validation from '../../utils/Validation';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
// import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { ReactComponent as TooltipIcon } from '../../assets/tooltip.svg';
import Constants from '../../utils/Constants';

const allTimezones = Constants.getAllTimezones();

const { currencyOptions } = Constants;

const customStyles = {
  editPhotoContainer: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  editPhotoBtn: {
    width: 110
  },
  userPhotoContainer: {
    width: 52,
    height: 52,
    marginRight: 20,
    borderRadius: 26,
    backgroundColor: '#5289ff'
  },
  userPhoto: {
    width: 52,
    height: 52,
    borderRadius: 26
  },
  initials: {
    fontSize: 26
  },
  firstLastContainer: {
    display: 'flex',
    alignItems: 'stretch'
  },
  editTitle: {
    marginBottom: 10
  },
  editInput: {
    marginBottom: 30
  },
  editContainer: {
    flex: 1,
    flexDirection: 'column'
  },
  firstNameContainer: {
    flex: 1,
    flexDirection: 'column',
    marginRight: 48
  },
  footerBtnContainer: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  saveBtn: {
    maxWidth: 110
  },
  deleteAccountBtn: {
    maxWidth: 200,
    color: '#4a4a4a'
  },
  saved: {
    color: '#0caf1d',
    fontSize: '14px',
    fontWeight: '500',
    marginLeft: '10px'
  }
};
class EditProfile extends PureComponent {
  state = {
    firstName: this.props.auth.user.first_name,
    lastName: this.props.auth.user.last_name,
    email: this.props.auth.user.email,
    currency: this.props.auth.user.currency
      ? currencyOptions.find(item => item.value === this.props.auth.user.currency)
      : currencyOptions[0],
    uploadedImage: null,
    uploadedImageFile: null,
    timezone: {
      value: this.props.auth.user.timezone || '(GMT-07:00) America/Los_Angeles',
      label: this.props.auth.user.timezone || '(GMT-07:00) America/Los_Angeles'
    },
    errors: {},
    loading: false,
    saved: false
  };

  onEditPhotoClick = () => {
    this.imageUploader.click();
  };

  onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ uploadedImageFile: event.target.files[0] });
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({ uploadedImage: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  onInputChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  onOptionChange = (name, selectedOption) => {
    this.setState({ [name]: selectedOption });
  };

  onSaveClick = async () => {
    this.setState({ loading: true, saved: false });
    const { firstName, lastName, email, uploadedImageFile, timezone, currency } = this.state;
    const { updateUser, auth } = this.props;

    const requiredData = {
      firstName,
      lastName,
      email
    };

    const newErrors = Validation.validateForm(requiredData);
    if (newErrors) {
      this.setState({ errors: newErrors, loading: false });
      console.log('invalidForm: ', newErrors);
      return;
    }

    try {
      const updateData = {
        first_name: requiredData.firstName,
        last_name: requiredData.lastName,
        email: requiredData.email,
        ...(currency.value && { currency: currency.value }),
        ...(timezone.value && { timezone: timezone.value })
      };

      if (uploadedImageFile) {
        const downloadUrl = await User.uploadPhoto(auth.user.id, uploadedImageFile);
        updateData.profile_picture = downloadUrl;
      }
      console.log(updateData);

      if (timezone.value) {
        moment.tz.setDefault(timezone.value.substr(timezone.value.indexOf(') ') + 2));
      }

      if (updateData.email !== auth.user.email) {
        await User.updateEmailFirebase(updateData.email);
      }
      const result = await updateUser(auth.user.id, updateData);
      if (result) {
        this.setState({
          saved: true,
          errors: {},
          loading: false,
          uploadedImage: null,
          uploadedImageFile: null
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        errors: { save: error.message },
        loading: false
      });
    }
  };

  onDeleteAccountClick = () => {
    console.log('delete account');
  };

  componentDidMount = () => {
    // window.scrollTo(0, 0);
    ReactTooltip.rebuild();
  };

  render() {
    const { firstName, lastName, email, timezone, currency, errors, loading, saved } = this.state;
    // const { profile_picture: profilePicture } = this.props.auth.user;
    // let userPicture = null;
    // if (profilePicture) {
    //   userPicture = `${profilePicture}?${new Date().getTime()}`;
    // }
    // if (uploadedImage) {
    //   userPicture = uploadedImage;
    // }

    return (
      <div className={styles.content}>
        <h1 className={styles.title}>Edit Profile</h1>
        <ReactTooltip
          effect="solid"
          delayHide={500}
          delayUpdate={500}
          className={styles.emailChangeTooltip}
          id="emailChange"
        >
          To change your account email, please{' '}
          <a href="mailto:info@fraudblocker.com?subject=Change Account Email Address">email</a> our
          support team.
        </ReactTooltip>
        <p>Adjust your email and profile settings here.</p>
        <div style={customStyles.editPhotoContainer}>
          {/* <ProfilePicture
            profilePicture={userPicture}
            pictureStyle={customStyles.userPhoto}
            containerStyle={customStyles.userPhotoContainer}
            initialsStyle={customStyles.initials}
            initials={`${firstName ? firstName[0] : email[0]}${lastName ? lastName[0] : email[1]}`}
          />
          <Button
            title="Edit Photo"
            color="grey"
            onClick={this.onEditPhotoClick}
            style={customStyles.editPhotoBtn}
          /> */}
          <input
            type="file"
            /* eslint-disable */
            ref={ref => {
              this.imageUploader = ref;
            }} // Store reference
            /* eslint-enable */
            accept="image/jpg,image/png"
            onChange={this.onImageChange}
            className={styles.uploadImageInput}
            id="group_image"
          />
        </div>

        <div style={customStyles.firstLastContainer}>
          <div style={customStyles.firstNameContainer}>
            <p style={customStyles.editTitle}>First Name</p>
            <Input
              containerStyle={customStyles.editInput}
              onChange={this.onInputChange}
              value={firstName}
              name="firstName"
              error={errors.firstName}
            />
          </div>
          <div style={customStyles.editContainer}>
            <p style={customStyles.editTitle}>Last Name</p>
            <Input
              containerStyle={customStyles.editInput}
              onChange={this.onInputChange}
              value={lastName}
              name="lastName"
              error={errors.lastName}
            />
          </div>
        </div>

        <div style={customStyles.emailContainer}>
          <p style={customStyles.editTitle}>
            Email Address
            <TooltipIcon className={styles.emailChangeTip} data-tip data-for="emailChange" />
          </p>
          <Input
            containerStyle={customStyles.editInput}
            onChange={this.onInputChange}
            value={email}
            name="email"
            error={errors.email}
            disabled={true}
          />
        </div>
        <div style={customStyles.firstLastContainer}>
          <div style={customStyles.firstNameContainer}>
            <p style={customStyles.editTitle}>Time Zone</p>
            <Dropdown
              options={allTimezones.values}
              onOptionChange={val => this.onOptionChange('timezone', val)}
              style={customStyles.dropdown}
              value={timezone}
            />
          </div>
          <div style={customStyles.editContainer}>
            <p style={customStyles.editTitle}>Currency</p>
            <Dropdown
              options={currencyOptions}
              value={currency}
              selectClass={styles.currencySelection}
              style={customStyles.dropdown}
              name="currency"
              onOptionChange={val => this.onOptionChange('currency', val)}
            />
          </div>
        </div>
        {errors.save && (
          <div className={styles.errorBox}>
            <ErrorBox error={errors.save} />
          </div>
        )}

        <div style={customStyles.footerBtnContainer}>
          <Button
            title="Save"
            color="blue"
            loading={loading}
            style={customStyles.saveBtn}
            onClick={this.onSaveClick}
          />
          {saved && <span style={customStyles.saved}>✓ Your profile was updated</span>}
          {/* <Button
            title="Delete Account and Data"
            color="none"
            style={customStyles.deleteAccountBtn}
            onClick={this.onDeleteAccountClick}
          /> */}
        </div>
      </div>
    );
  }
}

EditProfile.propTypes = {
  updateUser: PropTypes.func,
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (id, data) => dispatch(User.updateUser(id, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
